// import Sidebar from "./Sidebar";
import Header from "./Header";
import { useAuth } from "../context/auth";
import Opsheader from "./Opsheader";

const Layout = () => {
  const [auth, setAuth] = useAuth();

  return (
    <div>
      {auth && auth.userDetails?.defaultPersona === "PFT" ? (
        <Opsheader />
      ) : (
        <Header />
      )}
      <div style={{ display: "flex", marginTop: "0px" }}>
        {/* <Sidebar option={0} /> */}
      </div>
    </div>
  );
};

export default Layout;
