import {
  Alert,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  NativeSelect,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import {
  kycContextObjController,
  kycMultiGstIdController,
  objectforGetERP,
  profileContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { Box } from "@mui/system";

function ProfileERP({
  profileDetailsApiData,
  firmMemberDetailsList,
  allTypesOfFirmsData,
  gstinList,
  isLoading,
  setIsLoading,
}) {
  const ariaLabel = { "aria-label": "description" };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [erpAccessCheck, setErpAccessCheck] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [auth, setAuth] = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [gstinListt, setgstinList] = useState([]);
  const [gstID, setGstID] = useState("");
  const isAccessDenied = erpAccessCheck === false || erpAccessCheck === null;
  const isAccessAllowed = erpAccessCheck === true;
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const handleDropDownChange = (event) => {
    //setGstNumber(event.target.value);
    setGstID(event.target.value);
    getErpAccessDetails(event.target.value);
  };
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );

  useEffect(() => {
    //setErpAccessCheck(profileDetailsApiData?.erpAccess);
    if (gstinListt?.length > 0) {
      setGstNumber(gstinListt[0]?.gstId);
      setGstID(gstinListt[0]?.id);
      getErpAccessDetails(gstinListt[0]?.id);
    }
    setgstinList(gstinList);
  }, [profileDetailsApiData, gstinList]);

  console.log("GST ID is from gstInList :", gstID);

  const getErpAccessDetails = async (id) => {
    const Gstid = id;
    setIsLoading(true);
    try {
      const constructObj = kycMultiGstIdController(
        authContextObj,
        Gstid,
        anchorTraderID
      );
      const multiGstKycDetails = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-kyc-master-record"
      );

      if (multiGstKycDetails.res.status === 200) {
        console.log(
          "isERPAccessAllowed",
          multiGstKycDetails?.res?.data?.data?.kycMaster?.isERPAccessAllowed
        );
        setErpAccessCheck(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.isERPAccessAllowed
        );
      }
    } catch (err) {
      console.log("Error", err);
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const saveProfileErpDetail = async (newErpCheck) => {
    const isErpAccess = newErpCheck;
    setIsLoading(true);
    try {
      const constructObj = objectforGetERP(authContextObj, gstID, isErpAccess);
      const saveProfileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "update-erp-access"
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      console.log("Error", err);
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while saving");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <div className="body-text">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div
              style={{
                color: "#4A3AFF",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Process Of Installation:
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ul>
                  <li>The software will be installed in your ERP system.</li>
                  <li>
                    There will be plugins, and pre-requisites to be installed.
                  </li>
                  <li>
                    We shall assist you in the process, which will take 1-2
                    days, after your consent for access.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <div>
              <div
                style={{
                  color: "#4A3AFF",
                  fontWeight: "bold",
                }}
              >
                Data security and access:
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <div>The data is completely safe at ProFinTech.</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ul>
                    <li>Fully secure and full encryption of data.</li>
                    <li>
                      Explicit consent or approval will be taken from the
                      customer.
                    </li>
                    <li>
                      Agreement will be signed with the customer to ensure that
                      there is formal alignment.
                    </li>
                    <li>
                      Personal data, customer pricing, product details will
                      never be shared with any 3rd party.
                    </li>
                    <li>Developed and deployed on AWS cloud.</li>
                  </ul>
                </div>
                <div>
                  Please provide your consent for accessing your ERP Data.
                </div>
              </div>
            </div>
          </div>

          {/* <h4
          style={{ marginBottom: "5px", marginTop: "60px", color: "#4A3AFF" }}
        >
          {" "}
          <Checkbox
            {...label}
            value={checkBoxValue}
            onChange={() => setCheckboxValue(!checkBoxValue)}
          />
          I hereby declare that the information given above and in the enclosed
          documents is true to the best of my knowledge and belief. Nothing has
          been concealed therein. I acknowledge and accept the terms and
          conditions associated with this agreement.
        </h4> */}
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#FFF",
                color: "#4A3AFF",
                border: "1px solid rgba(0, 0, 128, 0.20)",
                padding: "10px 20px",
                cursor: isAccessAllowed ? "pointer" : "not-allowed",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                opacity: isAccessAllowed ? "1" : "0.5",
              }}
              onClick={() => {
                setErpAccessCheck(false);
                saveProfileErpDetail(false);
              }}
              disabled={!isAccessAllowed}
            >
              Reject Access
            </button>
            <button
              style={{
                marginLeft: "10px",
                borderRadius: "2px",
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: isAccessDenied ? "pointer" : "not-allowed",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                opacity: isAccessDenied ? "1" : "0.5",
              }}
              onClick={() => {
                setErpAccessCheck(true);
                saveProfileErpDetail(true);
              }}
              disabled={!isAccessDenied}
            >
              Allow Access
            </button>
          </div>
        </div>{" "}
        <div>
          {" "}
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              GST ID
            </InputLabel>
            <NativeSelect
              sx={{
                width: "185px",
                border: "1px solid black", // Border around the dropdown
                borderRadius: "4px",
                padding: "0px 5px", // Rounded corners
              }}
              inputProps={{
                name: "GSTID",
                id: "uncontrolled-native",
              }}
              value={gstID}
              onChange={handleDropDownChange}
            >
              {gstinListt?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.gstId}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfileERP;
