import { Alert, Button } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../context/auth";
import styled from "styled-components";
import {
  CompleteSettlement,
  PreparetriggerSettlementBeneValidation,
} from "../../Common/Constants";
import { opscontroller } from "../../API/FP/Actions";

const Step1 = ({
  selectedRecordDetails,
  nextStep,
  setstep1output,
  handleChange,
  formData,
  requiredMaps,
  setsecondStep1Output,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [btnenable, setbtnenable] = useState(false);
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const [partcicipantDetails, setParticipantDetails] = useState(
    selectedRecordDetails?.participantSettlementList
  );

  const selectedParticipantRecord = (row) => {
    setstep1output(row);
  };

  console.log("participantSettlement is :");
  console.log("beneValidationS3Path is :");
  const [settlementData, setSettelementData] = useState([]);
  console.log("the console log is from step1 :", partcicipantDetails);
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>Step 1 : Participant Settlement Details</h2>
          <h3> Check the below Participant Settlement details :</h3>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Participant Id</th>
                  <th>Participant Name</th>
                  <th>Gst Id</th>
                  <th>Settlement Type</th>
                  <th>Charge Type</th>
                  <th>Settlement Amount</th>
                  <th>Settlement Due Date</th>
                  <th>Patron Of Payment</th>
                  <th>Recipient Of Payment</th>
                  <th>Participant Settlement Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {partcicipantDetails?.map((row, index) => {
                  // Count how many records before the current one have status "PSCD"
                  const completedCount = partcicipantDetails
                    .slice(0, index)
                    .filter(
                      (item) => item.participantSettlementStatus === "PSCD"
                    ).length;

                  // Check if the current row is the third record and the previous two are completed
                  const shouldTriggerOutput =
                    completedCount === 2 &&
                    row.participantSettlementStatus === "PSCR";

                  return (
                    <tr key={index}>
                      <td>{row?.id}</td>
                      <td>{row?.participantId}</td>
                      <td>{row?.participantName}</td>
                      <td>{row?.gstId}</td>
                      <td>{row?.settlementType}</td>
                      <td>{row?.chargeType}</td>
                      <td>{row?.settlementAmount}</td>
                      <td>{row?.settlementDueDate}</td>
                      <td>{row?.patronOfPayment}</td>
                      <td>{row?.recipientOfPayment}</td>
                      <td>{row?.participantSettlementStatus}</td>
                      <td>
                        {row?.chargeType === "FP_TRANSACTION_CHARGES" ||
                        row?.chargeType === "CRE_TRANSACTION_FEES" ? (
                          <>
                            <p>Not Applicable</p>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#4A3AFF",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                height: "fit-content",
                                boxShadow:
                                  "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                opacity:
                                  row?.participantSettlementStatus !== "PSCR"
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                if (shouldTriggerOutput) {
                                  setsecondStep1Output(true);
                                }
                                nextStep();
                                selectedParticipantRecord(row);
                              }}
                              disabled={
                                row?.participantSettlementStatus !== "PSCR"
                              }
                            >
                              {row?.participantSettlementStatus !== "PSCR"
                                ? "Completed"
                                : "Proceed"}
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        </div>
      </div>
    </StepContainer>
  );
};

export default Step1;

const StepContainer = styled.div`
  .step-content {
    max-width: 960px;
    margin: 0px 30px;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;
