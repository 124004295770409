import React, { useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";

function RequestDetails({ creditScoreReportCheck, setCreditScoreReportCheck }) {
  const { state } = useLocation();
  let navigate = useNavigate();

  let tableHeaderNames = [
    "Date",
    "Invoice Number",
    "Trade Partner Name",
    "Trade Amount (INR)",
    "Term (Days)",
  ];
  let tableData = [
    {
      date: "Nov 18, 2023",
      invoiceNumber: "INV569829",
      tradePartnerName: "Apollo Hospitals",
      tradeAmount: "4,50,000",
      term: 80,
    },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV403441",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,00,000",
    //   term: 70,
    // },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV457608",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,50,000",
    //   term: 70,
    // },
  ];

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Request details
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  {/* <tr>
                    {tableHeaderNames.map((x) => {
                      return <th>{x}</th>;
                    })}
                  </tr>

                  {tableData.map((item) => {
                    return (
                      <tr>
                        <td>{item.date}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.tradePartnerName}</td>
                        <td>{item.tradeAmount}</td>
                        <td>{item.term}</td>
                      </tr>
                    );
                  })} */}
                  <tr>
                    <th>Date of Request</th>
                    <td>Nov 19, 2023</td>
                  </tr>
                  <tr>
                    <th>Total requested amount (INR)</th>
                    <td>10,00,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Below are the list of trades
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    {tableHeaderNames?.map((x) => {
                      return <th>{x}</th>;
                    })}
                  </tr>

                  {tableData?.map((item) => {
                    return (
                      <tr>
                        <td>{item.date}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.tradePartnerName}</td>
                        <td>{item.tradeAmount}</td>
                        <td>{item.term}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
