import React from "react";
import EllipseImage from "../images/Ellipse.svg";
import NotFoundImage from "../images/DataNotFound.svg";

function Pagenotfound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        //position: "relative",
        justifyContent: "center",
        marginTop: "12%",
        alignItems: "center",
        overflowX: "hidden", // Added overflow-x hidden style
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={EllipseImage}
          alt="Ellipse"
          style={{
            position: "absolute",
            paddingTop: "10px",
          }}
        />
        <img
          src={NotFoundImage}
          alt="Data Not Found"
          style={{
            position: "absolute",
            padding: "30px",
          }}
        />
      </div>
      <p style={{ paddingTop: "100px" }}>No records found</p>
    </div>
  );
}

export default Pagenotfound;
