import React from "react";

const PaisabuddyDashboard = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 50px)",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <iframe
        //      src="http://127.0.0.1:5000/superset/dashboard/p/wWj9Vj6VpM8/" // Replace with the actual Paisabuddy dashboard URL
        // dev superset      src="http://a3a09cf6c794c410094f5c29c7756ebe-1110877928.ap-south-1.elb.amazonaws.com:8088/superset/dashboard/p/p3GvBBmvrYe/"
        // prod superset
        src="https://dashboard.profintech.in/superset/dashboard/p/nNg4JdQvM08/"
        title="Paisabuddy Dashboard"
        style={{
          border: "none",
          width: "100%",
          height: "100%",
          display: "block",
          overflow: "auto",
          margin: "0",
          padding: "30px",
        }}
        scrolling="yes"
        allowFullScreen
      />
    </div>
  );
};

export default PaisabuddyDashboard;
