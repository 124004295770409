import React, { useState } from "react";
import Step1 from "./Repaymentstep1";
import Step2 from "./Repaymentstep2";
import Step3 from "./Repaymentstep3";
import styled from "styled-components";
import { Stepper, StepLabel, Step } from "@mui/material";

const RepaymentWizard = ({ selectedRecordDetails }) => {
  const [step, setStep] = useState(1);
  const [step1output, setstep1output] = useState();
  const [step2output, setstep2output] = useState();
  const [step3output, setstep3output] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    country: "",
    district: "",
    city: "",
    landmark: "",
    postal: "",
  });

  const steps = [
    " Incoming Repayment ",
    " Reconcile Repayment ",
    "Set Settlement",
  ];
  console.log(
    "The repayment Wizard selectedRecordDetails is :",
    selectedRecordDetails
  );

  console.log("The step2output from wizard is ", step2output);
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (input, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [input]: value,
    }));
  };

  const handleSubmit = () => {
    console.log(formData);
    // You can add the API call here using fetch or axios
    // Example using fetch:
    // fetch('https://your-api-url.com/submit', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(formData)
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error('Error:', error));
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            selectedRecordDetails={selectedRecordDetails}
            nextStep={nextStep}
            setstep1output={setstep1output}
            handleChange={handleChange}
            formData={formData}
          />
        );
      case 2:
        return (
          <Step2
            selectedRecordDetails={selectedRecordDetails}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep2output={setstep2output}
          />
        );
      case 3:
        return (
          <Step3
            step2output={step2output}
            handleChange={handleChange}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <WizardUi>
      <div className="wizard">
        <div className="Stepper">
          <Stepper activeStep={step - 1} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index}>
                <div
                  style={{
                    background: "linear-gradient(to left,  #8BBEF1 , #D0D8E0 )",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </div>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="main-content">{renderStep()}</div>
      </div>
    </WizardUi>
  );
};

export default RepaymentWizard;

const WizardUi = styled.div`
  .Stepper {
    padding-top: 50px;
    padding-left: 50px;
    border-right: 1px solid #d0d8e0;
    background: linear-gradient(to left, #dee4ea, white);
    color: white;
  }
  .wizard {
    padding: 10px;
    border: 1px solid #77aaff;
    box-shadow: -1px 1px grey, -2px 2px grey, -3px 3px grey, -4px 4px grey,
      -5px 5px grey;
    display: flex;
    height: 100vh;
    justify-content: center;
    border: 0.1px solid;
    border-radius: 30px;
    margin-top: 50px;
  }

  .main-content {
    flex: 1;
    background-color: white;
    box-sizing: border-box;
  }

  .StepLabel {
    font-size: 1.2rem; // Adjust the font size as needed
    color: white; // Set the color to white
  }

  .MuiStepIcon-completed,
  .MuiStepIcon-active {
    color: #1434a4 !important; // Set the color for the completed and active tick marks
  }
`;
