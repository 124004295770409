import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { NumericFormat } from "react-number-format";
import {
  CreateRequestsInvoicesMockData,
  CreateRequestsInvoicesSelectedMockData,
} from "../MockData/AnchorTraderMock";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { callAPI, controllerAPI, getDisbursementData } from "../API/FP/Actions";
import CloseIcon from "@mui/icons-material/Close";
import {
  contextObj,
  contextObjforcreapicall,
  contextObjforlimitsapicall,
  controllerAPIForCRELimits,
  contextforfilterapicall,
  contextObjforcrepostapicall,
  dateFormat,
} from "../Common/Constants";
import { useAuth } from "../../context/auth";
import Pagenotfound from "../Common/pagenotfound";
import { CommentBankSharp, TextFormat } from "@mui/icons-material";
import { fontFamily, fontSize } from "@mui/system";
<script src="https://cdn.lordicon.com/lordicon.js"></script>;

function AnchorTraderRequestInformation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [auth, setAuth] = useAuth();
  const [requiredAmount, setRequiredAmount] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [apicreatereqdata, setapidata] = useState([]);
  const [apicreatereqdatacre, setapidatacre] = useState([]);
  const [apicreatereqdatafinance, setapidatafinancereq] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [norecord, setnorecord] = useState(false);
  const [showFetchingMessage, setShowFetchingMessage] = useState(false);
  const [dataLoaded, setdataloaded] = useState(false);
  const [spinnerLoading, setIsspinnerLoading] = useState(false);
  const [maxLiveRequests, setmaxLiveRequests] = useState(true);
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [requestValueRange, setRequestValueRange] = useState(true);
  const [popupcheckedsumchange, setpopupchecksumchange] = useState(0);
  const [totalpopup, settotalpopup] = useState(0);
  const [totalonsaved, settotalonsaved] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [onSavedRecords, setonSavedRecords] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [popupopen, setpopupopen] = useState(null);
  const [ispopupcheck, setispopupcheck] = useState(false);
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const [filteredarraydata, setfilteredarraydata] = useState([]);
  const textContent = "  Enter Required Amount To Get Invoice Suggestions ";
  const [count, setcount] = useState(0);
  const ariaLabel = { "aria-label": "description" };
  const openpopup = () => {
    setpopupbtnopen(true);
    Filterapicall();
    setpopupchecksumchange(
      requiredAmount * 1.1 - (totalAmountSelected + totalonsaved)
    );
    settotalpopup(0);
    setpopupopen(true);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
    setpopupopen(false);
    settotalpopup(0);
    setSelectedRecords([]);
  };
  const popupclose = () => {
    setpopupbtnopen(false);
    setpopupopen(false);
    settotalpopup(0);
  };
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    let month = date.toLocaleString("default", { month: "short" });
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
  }

  const authContextObjForATREQ = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const Filterapicall = async () => {
    try {
      setIsspinnerLoading(true);
      const constructObj = contextforfilterapicall(
        authContextObjForATREQ,
        anchorTraderID
      );
      const filterresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-filtered-trades"
      );
      if (filterresponse.res.status === 200) {
        console.log(filterresponse?.res?.data?.data);
        setfilteredarraydata(filterresponse?.res?.data?.data);
        setIsspinnerLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsspinnerLoading(false);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      try {
        const constructObj = contextObjforlimitsapicall(
          authContextObjForATREQ,
          anchorTraderID
        );
        const Reqresp = await controllerAPI(
          "post",
          auth?.token,
          constructObj,
          "process-application-limits"
        );
        if (Reqresp?.res?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }
        if (Reqresp?.res?.status === 200) {
          console.log("API Response:", Reqresp);

          if (Reqresp?.res?.data?.data?.maxLiveRequests === true) {
            setmaxLiveRequests(true);
          } else {
            setmaxLiveRequests(false);
          }
          if (Reqresp?.res?.data?.data?.requestValueRange === true) {
            setRequestValueRange(true);
          } else {
            setRequestValueRange(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    callApi();
  }, []);

  console.log("requestValueRange", requestValueRange);
  console.log("maxLiveRequests", maxLiveRequests);

  const postATReqData = async (
    credata,
    individualAssessmentList,
    selectedRecords,
    financeRequest
  ) => {
    setIsspinnerLoading(true);
    try {
      setIsspinnerLoading(true);
      const constructObj = contextObjforcrepostapicall(
        authContextObjForATREQ,
        anchorTraderID,
        credata,
        individualAssessmentList,
        selectedRecords,
        financeRequest
      );
      const ATReqresp = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "cb-cre-phase-2"
      );
      if (ATReqresp.res.status === 200) {
        setIsspinnerLoading(false);
        setdataloaded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setnorecord(true);
    } finally {
      setIsspinnerLoading(false);
      setShowFetchingMessage(false);
      navigate("/creditbazaar/finance-request");
      setIsTableVisible(false);
    }
  };
  const fetchATReqdata = async (amount) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const constructObj = contextObjforcreapicall(
        authContextObjForATREQ,
        anchorTraderID,
        amount
      );
      const ATReqresp = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "cb-cre-phase-1"
      );
      if (ATReqresp.res.status === 200) {
        setapidata(ATReqresp.res.data.data);
        setapidatacre(ATReqresp.res.data.data.cbcreProcess);
        setapidatafinancereq(ATReqresp.res.data.data.financeRequest);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const handlePopupCheckboxChange = (e, invoice) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setispopupcheck(true);
      const isSelected = selectedRecords.some((x) => x.id === invoice.id);
      if (!isSelected) {
        setSelectedRecords([...selectedRecords, invoice]);
      }
      setpopupchecksumchange(popupcheckedsumchange - invoice.tradeAmount);
    } else {
      // Remove the invoice if it is unchecked
      const updatedRecords = selectedRecords.filter((x) => x.id !== invoice.id);
      setSelectedRecords(updatedRecords);
      setpopupchecksumchange(popupcheckedsumchange + invoice.tradeAmount);
    }
  };

  const handlecustomselectedtrades = (e, invoice) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const isSelected = onSavedRecords.some((x) => x.id === invoice.id);
      if (!isSelected) {
        setonSavedRecords([...onSavedRecords, invoice]);
      }
      setpopupchecksumchange(popupcheckedsumchange - invoice.tradeAmount);
    } else {
      // Remove the invoice if it is unchecked
      const updatedRecords = onSavedRecords.filter((x) => x.id !== invoice.id);
      setonSavedRecords(updatedRecords);
      setpopupchecksumchange(popupcheckedsumchange + invoice.tradeAmount);
      let result = 0;
      updatedRecords?.map((invoice) => {
        result = result + invoice.tradeAmount;
      });
      settotalonsaved(result);
    }
  };
  const handleCheckboxChange = (invoiceId) => {
    setapidata((prevData) => {
      const updatedData = prevData.individualAssessmentList?.map((invoice) => {
        if (invoice.invoiceId === invoiceId) {
          return {
            ...invoice,
            isSelected: !invoice.isSelected,
          };
        }
        return invoice;
      });
      return { ...prevData, individualAssessmentList: updatedData };
    });
  };

  // Calculate total amount selected
  const totalAmountSelected = apicreatereqdata.individualAssessmentList?.reduce(
    (accumulator, currentValue) =>
      currentValue.isSelected
        ? accumulator + currentValue.invoiceAmount
        : accumulator,
    0
  );

  const handleSubmit = () => {
    console.log(
      "Submitted invoices:",
      setShowFetchingMessage(false),
      apicreatereqdata.individualAssessmentList
    );
    postATReqData(
      apicreatereqdatacre,
      apicreatereqdata.individualAssessmentList,
      onSavedRecords,
      apicreatereqdatafinance
    );
  };
  const popupsaved = () => {
    console.log("this is the person selected records", selectedRecords);
    setIsTableVisible(true);
    closepopup();
    setonSavedRecords([...onSavedRecords, ...selectedRecords]);
    console.log("onSavedRecords are", {
      ...onSavedRecords,
      ...selectedRecords,
    });
    let result = 0;
    [...onSavedRecords, ...selectedRecords]?.map((invoice) => {
      result = result + invoice.tradeAmount;
    });
    settotalonsaved(result);
  };

  if (!requestValueRange && !maxLiveRequests) {
    return (
      <>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "30px",
              }}
            >
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  Request for finance
                </h3>
                <Typography>
                  To initiate a finance request, below is a list of active
                  trades. Choose the trades as per your financing requirements.
                </Typography>
              </div>
              <div className="header">
                <Typography>
                  <div className="removeArrow">
                    <Input
                      inputProps={ariaLabel}
                      value={requiredAmount}
                      placeholder="Enter Required Amount"
                      type="number"
                      min="0"
                      InputProps={{ inputProps: { min: 0 } }}
                      autoFocus
                      sx={{
                        "& input::placeholder": {
                          fontSize: "15px",
                          fontWeight: "bold",
                        },
                      }}
                      disabled={
                        isLoading ||
                        requestValueRange !== true ||
                        maxLiveRequests !== true
                      }
                      onKeyPress={preventMinus}
                      onChange={(e) => {
                        setRequiredAmount(e.target.value);
                      }}
                    />
                    <button
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "7px 20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                        borderRadius: "2px",
                        height: "fit-content",
                        opacity:
                          !isLoading && requiredAmount && requiredAmount !== "0"
                            ? "1"
                            : "0.5",
                      }}
                      disabled={
                        !isLoading && requiredAmount && requiredAmount !== "0"
                          ? false
                          : true
                      }
                      onClick={() => {
                        const longAmount = parseFloat(requiredAmount);
                        fetchATReqdata(longAmount);
                        setcount(count + 1);

                        console.log("clicked this many times,", count);
                      }}
                    >
                      Request Finance
                    </button>
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              fontSize: "50px",
              marginBottom: "10px",
            }}
          >
            &#9888; {/* Warning icon */}
          </div>
          <div
            style={{
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            The request value range as well as maximum live requests allowed are
            crossed
          </div>
        </div>
      </>
    );
  }
  if (!requestValueRange) {
    return (
      <>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "30px",
              }}
            >
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  Request for finance
                </h3>
                <Typography>
                  To initiate a finance request, below is a list of active
                  trades. Choose the trades as per your financing requirements.
                </Typography>
              </div>
              <div className="header">
                <Typography>
                  <div className="removeArrow">
                    <Input
                      inputProps={ariaLabel}
                      value={requiredAmount}
                      placeholder="Enter Required Amount"
                      type="number"
                      min="0"
                      InputProps={{ inputProps: { min: 0 } }}
                      autoFocus
                      sx={{
                        "& input::placeholder": {
                          fontSize: "15px",
                          fontWeight: "bold",
                        },
                      }}
                      disabled={
                        isLoading ||
                        requestValueRange !== true ||
                        maxLiveRequests !== true
                      }
                      onKeyPress={preventMinus}
                      onChange={(e) => {
                        setRequiredAmount(e.target.value);
                      }}
                    />
                    <button
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "7px 20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                        borderRadius: "2px",
                        height: "fit-content",
                        opacity:
                          !isLoading && requiredAmount && requiredAmount !== "0"
                            ? "1"
                            : "0.5",
                      }}
                      disabled={
                        !isLoading && requiredAmount && requiredAmount !== "0"
                          ? false
                          : true
                      }
                      onClick={() => {
                        const longAmount = parseFloat(requiredAmount);
                        fetchATReqdata(longAmount);
                        setcount(count + 1);

                        console.log("clicked this many times,", count);
                      }}
                    >
                      Request Finance
                    </button>
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              fontSize: "50px",
              marginBottom: "10px",
            }}
          >
            &#9888; {/* Warning icon */}
          </div>
          <div
            style={{
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            The cumulative value of all live requests has exceeded the
            permissible range
          </div>
        </div>
      </>
    );
  }

  if (!maxLiveRequests) {
    return (
      <>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "30px",
              }}
            >
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  Request for finance
                </h3>
                <Typography>
                  To initiate a finance request, below is a list of active
                  trades. Choose the trades as per your financing requirements.
                </Typography>
              </div>
              <div className="header">
                <Typography>
                  <div className="removeArrow">
                    <Input
                      inputProps={ariaLabel}
                      value={requiredAmount}
                      placeholder="Enter Required Amount"
                      type="number"
                      min="0"
                      InputProps={{ inputProps: { min: 0 } }}
                      autoFocus
                      sx={{
                        "& input::placeholder": {
                          fontSize: "15px",
                          fontWeight: "bold",
                        },
                      }}
                      disabled={
                        isLoading ||
                        requestValueRange !== true ||
                        maxLiveRequests !== true
                      }
                      onKeyPress={preventMinus}
                      onChange={(e) => {
                        setRequiredAmount(e.target.value);
                      }}
                    />
                    <button
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "7px 20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                        borderRadius: "2px",
                        height: "fit-content",
                        opacity:
                          !isLoading && requiredAmount && requiredAmount !== "0"
                            ? "1"
                            : "0.5",
                      }}
                      disabled={
                        !isLoading && requiredAmount && requiredAmount !== "0"
                          ? false
                          : true
                      }
                      onClick={() => {
                        const longAmount = parseFloat(requiredAmount);
                        fetchATReqdata(longAmount);
                        setcount(count + 1);

                        console.log("clicked this many times,", count);
                      }}
                    >
                      Request Finance
                    </button>
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              fontSize: "50px",
              marginBottom: "10px",
            }}
          >
            &#9888; {/* Warning icon */}
          </div>
          <div
            style={{
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            You have exceeded the permissible live requests
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "30px",
            }}
          >
            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Request for finance
              </h3>
              <Typography>
                To initiate a finance request, below is a list of active trades.
                Choose the trades as per your financing requirements.
              </Typography>
            </div>
            <div className="header">
              <Typography>
                <div className="removeArrow">
                  <Input
                    inputProps={ariaLabel}
                    value={requiredAmount}
                    placeholder="Enter Required Amount"
                    type="number"
                    min="0"
                    InputProps={{ inputProps: { min: 0 } }}
                    autoFocus
                    sx={{
                      "& input::placeholder": {
                        fontSize: "15px",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={
                      isLoading ||
                      requestValueRange !== true ||
                      maxLiveRequests !== true
                    }
                    onKeyPress={preventMinus}
                    onChange={(e) => {
                      setRequiredAmount(e.target.value);
                    }}
                  />
                  <button
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "7px 20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      borderRadius: "2px",
                      height: "fit-content",
                      opacity:
                        !isLoading && requiredAmount && requiredAmount !== "0"
                          ? "1"
                          : "0.5",
                    }}
                    disabled={
                      !isLoading && requiredAmount && requiredAmount !== "0"
                        ? false
                        : true
                    }
                    onClick={() => {
                      const longAmount = parseFloat(requiredAmount);
                      fetchATReqdata(longAmount);
                      setcount(count + 1);

                      console.log("clicked this many times,", count);
                    }}
                  >
                    Request Finance
                  </button>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </Finance1>

      {isLoading ? (
        <div
          className="loading-container"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            zIndex: "1",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <lord-icon
            src="https://cdn.lordicon.com/cosvjkbu.json"
            trigger="loop"
            delay="400"
            colors="primary:#9cc2f4,secondary:#646e78,tertiary:#3a3347"
            style={{ width: "150px", height: "150px" }}
          ></lord-icon>
          <p>Fetching the best offers for you</p>
        </div>
      ) : (
        <>
          {apicreatereqdata.individualAssessmentList &&
          apicreatereqdata.individualAssessmentList.length > 0 ? (
            <Dash className="body-text">
              {showTable && (
                <div className="main-content">
                  <div className="record-details">
                    {isLoading ? (
                      <></>
                    ) : (
                      <>
                        <Typography>
                          <h3>Trades Reccomended for you </h3>
                        </Typography>
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Invoice Number</th>
                              <th>Trade Partner Name</th>
                              <th>Amount (INR)</th>
                              <th>Term (Days)</th>
                              <th>Trade Partner Review</th>
                              <th>
                                {/* <input type="checkbox" className="clickable" /> */}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {spinnerLoading ? (
                              <CircularProgress
                                style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                }}
                              />
                            ) : (
                              <>
                                {apicreatereqdata.individualAssessmentList?.map(
                                  (invoice, index) => (
                                    <tr key={index}>
                                      <td>
                                        {dateFormat(
                                          new Date(invoice?.invDate),
                                          0
                                        ) || "-"}
                                      </td>
                                      <td>{invoice.invoiceId || "-"}</td>
                                      <td>{invoice.tradePartnerName || "-"}</td>
                                      <td>
                                        <NumericFormat
                                          displayType="text"
                                          value={invoice.invoiceAmount}
                                          thousandsGroupStyle="lakh"
                                          thousandSeparator=","
                                        />
                                      </td>
                                      <td>{invoice.term || "-"}</td>
                                      <td>
                                        <span
                                          style={{
                                            padding: "2px 15px",
                                            backgroundColor:
                                              invoice.tradePartnerConfirmation ===
                                              "Approved"
                                                ? "rgba(216, 247, 230, 1)"
                                                : "rgba(74, 58, 255, 0.2)",
                                            color:
                                              invoice.tradePartnerConfirmation ===
                                              "Approved"
                                                ? "#00692A"
                                                : "#4A3AFF",
                                            borderRadius: "100px",
                                            display: "block",
                                            textAlign: "center",
                                          }}
                                        >
                                          {invoice.tradePartnerConfirmation ===
                                          "Approved"
                                            ? invoice.tradePartnerReview
                                            : "Not approved"}
                                        </span>
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          className="clickable"
                                          checked={invoice.isSelected || false}
                                          onChange={() => {
                                            // Check if the checkbox is being unchecked or if the condition allows checking
                                            if (
                                              invoice.isSelected ||
                                              invoice.invoiceAmount <=
                                                requiredAmount * 1.1 -
                                                  (totalAmountSelected +
                                                    totalonsaved)
                                            ) {
                                              handleCheckboxChange(
                                                invoice.invoiceId
                                              );
                                            }
                                          }}
                                          disabled={
                                            !invoice.isSelected &&
                                            invoice.invoiceAmount >
                                              requiredAmount * 1.1 -
                                                (totalAmountSelected +
                                                  totalonsaved)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>

                  {requiredAmount && (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "15px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Requested amount:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          INR{" "}
                          <NumericFormat
                            displayType="text"
                            value={requiredAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Total CRE selected trade amount:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          INR{" "}
                          <NumericFormat
                            displayType="text"
                            value={totalAmountSelected}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Tenure:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          <NumericFormat
                            displayType="text"
                            value={"-"}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />{" "}
                          days
                        </span>
                      </div>
                    </div>
                  )}

                  <div style={{ color: "#4A3AFF" }}>
                    If you find the tenure or trades unsatisfactory, please
                    re-enter the amount again.
                  </div>
                  {requiredAmount * 1.1 -
                    (totalAmountSelected + totalonsaved) <=
                  0 ? (
                    <Typography style={{ marginTop: "20px", color: "red" }}>
                      You can not select any new trades
                    </Typography>
                  ) : (
                    <Typography style={{ marginTop: "20px" }}>
                      You can select amount upto
                      <span style={{ fontWeight: "bold" }}>
                        {"  "} INR {"  "}
                        <NumericFormat
                          displayType="text"
                          value={(
                            requiredAmount * 1.1 -
                            (totalAmountSelected + totalonsaved)
                          ).toFixed(2)}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </span>
                    </Typography>
                  )}
                  <button
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      width: "130px",
                      margin: "10px",
                      opacity:
                        requiredAmount * 1.1 -
                          (totalAmountSelected + totalonsaved) <=
                        0
                          ? "0.5"
                          : "1",
                    }}
                    onClick={openpopup}
                    disabled={
                      requiredAmount * 1.1 -
                        (totalAmountSelected + totalonsaved) <=
                      0
                    }
                  >
                    See all Trades
                  </button>

                  {isTableVisible && (
                    <>
                      <Typography>
                        <h3>Trades selected by you </h3>
                      </Typography>
                      <table
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginBottom: "20px",
                          border: "1px solid #c6c6c6",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Id
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Amount (INR)
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Partner Name
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Term (Days)
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Partner Review
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {spinnerLoading ? (
                            <tr>
                              <td colSpan="7" style={{ textAlign: "center" }}>
                                <CircularProgress
                                  style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                  }}
                                />
                              </td>
                            </tr>
                          ) : (
                            onSavedRecords?.map((invoice, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {new Date(
                                    invoice?.tradeDate
                                  ).toLocaleDateString() || "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {invoice.tradeNo || "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  <NumericFormat
                                    displayType="text"
                                    value={invoice.tradeAmount}
                                    thousandsGroupStyle="lakh"
                                    thousandSeparator=","
                                  />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {invoice.targetName || "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {invoice.term || "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      padding: "2px 15px",
                                      backgroundColor:
                                        invoice.tradePartnerConfirmation ===
                                        "Approved"
                                          ? "rgba(216, 247, 230, 1)"
                                          : "rgba(74, 58, 255, 0.2)",
                                      color:
                                        invoice.tradePartnerConfirmation ===
                                        "Approved"
                                          ? "#00692A"
                                          : "#4A3AFF",
                                      borderRadius: "100px",
                                      display: "block",
                                      textAlign: "center",
                                    }}
                                  >
                                    {invoice.tradePartnerConfirmation ===
                                    "Approved"
                                      ? invoice.tradePartnerReview
                                      : "Not approved"}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="clickable"
                                    checked={true}
                                    onChange={(e) => {
                                      handlecustomselectedtrades(e, invoice);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "20px",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    Grand Total trade amount:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      INR{" "}
                      <NumericFormat
                        displayType="text"
                        value={totalAmountSelected + totalonsaved}
                        thousandsGroupStyle="lakh"
                        thousandSeparator=","
                      />
                    </span>
                  </div>
                  <div style={{ marginTop: "15px", textAlign: "center" }}>
                    <button
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        cursor: "pointer",
                        opacity:
                          apicreatereqdata.length !== 0 &&
                          totalAmountSelected + totalonsaved === 0
                            ? "0.5"
                            : "1",
                      }}
                      onClick={() =>
                        totalAmountSelected + totalonsaved > 0 && handleSubmit()
                      }
                    >
                      Confirm
                    </button>
                  </div>

                  <Dialog
                    open={popupbtnopen}
                    onClose={() => {
                      closepopup();
                      popupclose();
                    }}
                    fullWidth
                    maxWidth="md"
                    PaperProps={{
                      style: { borderRadius: 20 },
                    }}
                  >
                    <DialogTitle>Filtered data</DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={closepopup}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent>
                      {isLoading && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            zIndex: "1100",
                          }}
                        />
                      )}
                      <DialogContentText>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          You can choose desired trade from here upto{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {"  "} INR {"  "}
                            <NumericFormat
                              displayType="text"
                              value={popupcheckedsumchange.toFixed(2)}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </span>
                          <div className="record-details">
                            {isLoading ? (
                              <></>
                            ) : (
                              <table
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  marginBottom: "20px",
                                  border: "1px solid #c6c6c6",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Date
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Trade Id
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Trade Amount (INR)
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Trade Partner Name
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Term (Days)
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Trade Partner Review
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {spinnerLoading ? (
                                    <tr>
                                      <td
                                        colSpan="7"
                                        style={{ textAlign: "center" }}
                                      >
                                        <CircularProgress
                                          style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    filteredarraydata?.map((invoice, index) => (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {new Date(
                                            invoice?.tradeDate
                                          ).toLocaleDateString() || "-"}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {invoice.tradeNo || "-"}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <NumericFormat
                                            displayType="text"
                                            value={invoice.tradeAmount}
                                            thousandsGroupStyle="lakh"
                                            thousandSeparator=","
                                          />
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {invoice.targetName || "-"}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {invoice.term || "-"}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              padding: "2px 15px",
                                              backgroundColor:
                                                invoice.tradePartnerConfirmation ===
                                                "Approved"
                                                  ? "rgba(216, 247, 230, 1)"
                                                  : "rgba(74, 58, 255, 0.2)",
                                              color:
                                                invoice.tradePartnerConfirmation ===
                                                "Approved"
                                                  ? "#00692A"
                                                  : "#4A3AFF",
                                              borderRadius: "100px",
                                              display: "block",
                                              textAlign: "center",
                                            }}
                                          >
                                            {invoice.tradePartnerConfirmation ===
                                            "Approved"
                                              ? invoice.tradePartnerReview
                                              : "Not approved"}
                                          </span>
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="clickable"
                                            onChange={(e) =>
                                              handlePopupCheckboxChange(
                                                e,
                                                invoice
                                              )
                                            }
                                            disabled={
                                              invoice.tradeAmount >
                                              popupcheckedsumchange
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            )}
                          </div>
                          <button
                            type="button"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#4A3AFF",
                              color: "white",
                              border: "none",
                              padding: "8px 8px",
                              cursor: "pointer",
                              height: "fit-content",
                              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                              margin: "5px",
                              width: "100px",
                              position: "absolute",
                              right: 8,
                              bottom: 8,
                            }}
                            onClick={popupsaved} // Pass the function reference, do not invoke it
                          >
                            Save
                          </button>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions></DialogActions>
                  </Dialog>
                </div>
              )}
            </Dash>
          ) : (
            <>
              {count > 0 ? (
                <div>
                  <div
                    style={{
                      color: "red",
                      marginTop: "20px",
                    }}
                  >
                    <Typography>Try entering different amount !!</Typography>
                  </div>
                  <Pagenotfound />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      zIndex: "1",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <svg
                      width="180px"
                      height="180px"
                      viewBox="0 0 64 64"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      style={{ animation: "bounce 2s infinite" }}
                    >
                      <style>
                        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-10px);
            }
            60% {
              transform: translateY(-5px);
            }
          }
          .primary { stroke: #848484; }
          .secondary { fill: #ebe6ef; }
          .tertiary { fill: #66a1ee; }
        `}
                      </style>
                      <rect
                        x="12"
                        y="4"
                        width="40"
                        height="56"
                        rx="4"
                        ry="4"
                        className="secondary"
                      />
                      <line
                        x1="20"
                        y1="12"
                        x2="44"
                        y2="12"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="20"
                        x2="44"
                        y2="20"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="28"
                        x2="44"
                        y2="28"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="36"
                        x2="32"
                        y2="36"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="44"
                        x2="32"
                        y2="44"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="52"
                        x2="32"
                        y2="52"
                        className="primary"
                      />
                      <text x="35" y="50" className="tertiary" fontSize="20px">
                        ₹
                      </text>
                    </svg>
                    <span
                      style={{ fontFamily: "YourFontFamily", fontSize: "25px" }}
                    >
                      {textContent}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* <div style={{ marginTop: "30vh" }}>
              <Footer />
          </div> */}
    </>
  );
}

export default AnchorTraderRequestInformation;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .fade-in {
    opacity: 0;
    display: inline-block;
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
