import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  objectforBeneval,
  PrepareParticipantSettlement,
} from "../../Common/Constants";
import { opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";

const Step2 = ({
  step1output,
  selectedRecordDetails,
  nextStep,
  handleChange,
  formData,
  setstep2output,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const prepareParticipantSettlement = async (
    financeRequestId,
    participantSettlementId
  ) => {
    const constructObj = PrepareParticipantSettlement(
      financeRequestId,
      participantSettlementId
    );
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "prepare-participant-settlement"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("participant settlement prepared successfully ");
        setAlertSeverity("success");
        setstep2output(response?.res?.data?.data);
        setdata(response?.res?.data?.data);
        setbtnenable(true);
        setshowdata(true);
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
      if (response?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Bene Validation!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  console.log(
    "Finance request id from selectedRecordDetails is :",
    selectedRecordDetails?.financeRequest?.id
  );
  console.log("data is :", data);
  console.log("financeRequest is :", data?.financeRequest);
  console.log("requestOffer is :", data?.requestOffer);
  console.log("placedOffer is :", data?.placedOffer);
  console.log("acceptedOffer is :", data?.acceptedOffer);
  console.log("repayment is :", data?.repayment);
  console.log("settlement is :", data?.settlement);
  console.log("The participant settlement id is :", step1output?.id);
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 2: Prepare Participant Settlement</h2>
          <h3>
            {" "}
            Confirm the selected Participant settlemet details and Proceed :
          </h3>
          <p> Id :{step1output?.id}</p>
          <p>GST Id :{step1output?.gstId}</p>
          <p>Recipient Of Payment:{step1output?.recipientOfPayment}</p>
          <p>EmailId :{step1output?.emailId}</p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  prepareParticipantSettlement(
                    selectedRecordDetails?.financeRequest?.id,
                    step1output?.id
                  );
                }}
              >
                Proceed
              </Button>
            </div>
          )}

          {showdata === true ? (
            <>
              <p>
                Bene Validation Payload Created and Stored In S3:{" "}
                <a
                  href={data?.beneValidationS3Path}
                  style={{ fontWeight: "bold" }}
                  target="_blank"
                >
                  {data?.beneValidationS3Path}
                </a>
              </p>
              <p>
                Funds Transfer Payload Created and Stored In S3:{" "}
                <a
                  href={data?.fundTransferS3Path}
                  target="_blank"
                  style={{ fontWeight: "bold" }}
                >
                  {data?.fundTransferS3Path}
                </a>
              </p>
            </>
          ) : (
            <>
              <p></p>
            </>
          )}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={nextStep}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step2;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
