import React, { useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import arrowLeft from "../../../components/images/arrow.svg";
import { newstates } from "../../Common/Constants";
function TradePartnerReport({ invoicesData, financeRequestData }) {
  const [totalKeyValue, setTotalKeyValue] = useState(
    invoicesData
      ? invoicesData.filter(
          (v, i, a) => a.findIndex((v2) => v2.buyerGstId === v.buyerGstId) === i
        )
      : []
  );
  const [presentKeyValue, setPresentKeyValue] = useState(
    invoicesData.length > 0 ? invoicesData[0] : {}
  );
  const [presentKeyValueIndex, setPresentKeyValueIndex] = useState(0);

  const handleRightClick = () => {
    setPresentKeyValueIndex(presentKeyValueIndex + 1);
    setPresentKeyValue(totalKeyValue[presentKeyValueIndex + 1]);
  };
  const handleLeftClick = () => {
    setPresentKeyValueIndex(presentKeyValueIndex - 1);
    setPresentKeyValue(totalKeyValue[presentKeyValueIndex - 1]);
  };

  const returnValue = (val) => {
    let v1 = parseInt(val);
    if (v1 > 0 && v1 <= 100) {
      return val;
    } else {
      return "0";
    }
  };

  return (
    <>
      <div>
        <RequestInformation style={{ display: "flex" }}>
          <div className="main-content" style={{ width: "60%" }}>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Trade Partner name</th>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          {presentKeyValue?.tradePartnerName}
                        </div>
                        <div>
                          <img
                            src={arrowLeft}
                            style={{
                              transform: "rotate(180deg)",
                              marginRight: "20px",
                              cursor: "pointer",
                              opacity: presentKeyValueIndex === 0 ? "0.1" : "1",
                            }}
                            onClick={
                              presentKeyValueIndex === 0
                                ? console.log("left")
                                : handleLeftClick
                            }
                          />{" "}
                          <img
                            src={arrowLeft}
                            style={{
                              cursor: "pointer",
                              opacity:
                                presentKeyValueIndex + 1 ===
                                totalKeyValue.length
                                  ? "0.1"
                                  : "1",
                            }}
                            onClick={
                              presentKeyValueIndex + 1 === totalKeyValue.length
                                ? console.log("right")
                                : handleRightClick
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{presentKeyValue?.tradePartnerLocation}</td>
                  </tr>
                  <tr>
                    <th>Trade Partner GST</th>
                    <td>{presentKeyValue?.buyerGstId}</td>
                  </tr>
                  <tr>
                    <th>Trade Partner sector</th>
                    <td>{presentKeyValue?.tradePartnerSector}</td>
                  </tr>
                  <tr>
                    <th>Acceptance from Trade Partner</th>
                    <td>
                      {presentKeyValue?.acceptanceFromTradePartner !==
                        undefined &&
                      presentKeyValue?.acceptanceFromTradePartner !== null
                        ? newstates(presentKeyValue?.acceptanceFromTradePartner)
                        : "Not Reviewed"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="main-content" style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  // width: "40%",
                  borderRadius: "2px",
                  paddingBottom: "5px",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  GST Compliance Score
                </p>
                <Divider variant="middle" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <CircleCustom
                    score={returnValue(
                      presentKeyValue?.tradePartnerGstComplianceScore
                    )}
                    text="CRE Score"
                  />
                  <div className="bullet-points-list">
                    <ul
                      style={{
                        textAlign: "center",
                        listStylePosition: "inside",
                      }}
                    >
                      <li>{"< 70 - Ineligible Cut-Off"}</li>
                      <li>{"> 70 - Eligible Cut-Off"}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TradePartnerReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
