import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import arrowLeft from "../../../components/images/arrow.svg";
import { callAPI, getObservations } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { contextObj } from "../../Common/Constants";
import { newstates } from "../../Common/Constants";

function TransactionReport({ authToken, invoicesData, financeRequestData }) {
  const [totalKeyValue, setTotalKeyValue] = useState(invoicesData);
  const [presentKeyValue, setPresentKeyValue] = useState(invoicesData[0]);
  const [presentIndividualAssessment, setPresentIndividualAssessment] =
    useState([]);
  const [presentKeyValueIndex, setPresentKeyValueIndex] = useState(0);
  const [observationArray, setObservationArray] = useState([]);
  const [presentObservation, setPresentObservation] = useState([]);
  const [auth, setAuth] = useAuth();

  const handleRightClick = () => {
    setPresentKeyValueIndex(presentKeyValueIndex + 1);
    setPresentKeyValue(totalKeyValue[presentKeyValueIndex + 1]);
    // setPresentObservation(observationArray.filter())
  };
  const handleLeftClick = () => {
    setPresentKeyValueIndex(presentKeyValueIndex - 1);
    setPresentKeyValue(totalKeyValue[presentKeyValueIndex - 1]);
  };

  const fetchPFTCreditRecommendationScore = useCallback(async () => {
    try {
      const constructObj1 = contextObj(
        financeRequestData?.requestOffer?.reqOffUlidId
      );
      const individualAssessmentResponse = await callAPI(
        auth?.token,
        constructObj1,
        "individual-assessments"
      );
      if (
        individualAssessmentResponse.res.data.data.message.msgDetails
          .individualAssessmentList.length !== 0
      ) {
        setPresentIndividualAssessment(
          individualAssessmentResponse.res.data.data.message.msgDetails.individualAssessmentList.filter(
            (item) =>
              item.tradePartnerGST ===
              financeRequestData?.requestOffer?.tradePartnerGst
          )
        );
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  const fetchObservations = useCallback(async () => {
    try {
      const response = await getObservations(auth?.token);
      if (response.res.data.length !== 0) {
        setObservationArray(
          response.res.data
          // .filter(
          //   (item) =>
          //     item.tradepartnerGST === financeRequestData?.requestOffer?.tradePartnerGst
          // )
        );
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  useEffect(() => {
    // fetchPFTCreditRecommendationScore();
    // fetchObservations();
  }, []);
  // console.log(
  //   "1111111111obbbbbbbbbbbbbbbbb",
  //   observationArray,
  //   presentKeyValue
  // );
  const returnValue = (val) => {
    let v1 = parseInt(val);
    if (v1 > 0 && v1 <= 100) {
      return val;
    } else {
      return "0";
    }
  };
  return (
    <>
      <div>
        <RequestInformation style={{ display: "flex" }}>
          <div className="main-content" style={{ width: "60%" }}>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Trade document number</th>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          {presentKeyValue?.invoiceNumber}
                        </div>
                        <div>
                          <img
                            src={arrowLeft}
                            style={{
                              transform: "rotate(180deg)",
                              marginRight: "20px",
                              cursor: "pointer",
                              opacity: presentKeyValueIndex === 0 ? "0.1" : "1",
                              padding: "0px 7px",
                            }}
                            onClick={
                              presentKeyValueIndex === 0
                                ? console.log("")
                                : handleLeftClick
                            }
                          />{" "}
                          <img
                            src={arrowLeft}
                            style={{
                              cursor: "pointer",
                              opacity:
                                presentKeyValueIndex + 1 ===
                                totalKeyValue.length
                                  ? "0.1"
                                  : "1",
                              padding: "0px 7px",
                            }}
                            onClick={
                              presentKeyValueIndex + 1 === totalKeyValue.length
                                ? console.log("")
                                : handleRightClick
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Source of Trade document</th>
                    <td>{presentKeyValue?.tradeDocSource}</td>
                  </tr>
                  <tr>
                    <th>Credibility of Trade document</th>
                    <td>{presentKeyValue?.tradeDocCredibility}</td>
                  </tr>
                  <tr>
                    <th>Trader Milestone status</th>
                    <td>{presentKeyValue?.tradeMilestoneStatus}</td>
                  </tr>
                  <tr>
                    <th>Advance payment made</th>

                    <td>
                      {presentKeyValue?.tradeAdvancePayment == true
                        ? "True"
                        : "False"}
                    </td>
                  </tr>
                  <tr>
                    <th>Acceptance from the Trade Partners</th>
                    <td>
                      {presentKeyValue?.acceptanceFromTradePartner !==
                        undefined &&
                      presentKeyValue?.acceptanceFromTradePartner !== null
                        ? newstates(presentKeyValue?.acceptanceFromTradePartner)
                        : "Not Reviewed"}
                    </td>
                  </tr>
                  <tr>
                    <th>Reason for finance (End Use)</th>
                    <td>{presentKeyValue?.reasonForFinance}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="main-content" style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                marginLeft: "5%",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  // width: "80%",
                  borderRadius: "2px",
                  // paddingBottom: "16px",
                  marginBottom: "5%",
                  height: "250px",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  ProFinTech credit recommendation score
                </p>
                <Divider variant="middle" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "15px",
                  }}
                >
                  <CircleCustom
                    score={returnValue(
                      financeRequestData?.cbcreProcess?.cumilativeTradeScore
                    )}
                    text="CRE Score"
                  />
                  <div className="bullet-points-list">
                    <ul
                      style={{
                        // textAlign: "center",
                        listStylePosition: "inside",
                      }}
                    >
                      {/* Credit Score: Risk */}
                      <li>0-20 : Very High</li>
                      <li>20-35: High</li>
                      <li>35-60: Medium</li>
                      <li>60-80: Low</li>
                      <li>80-100: Very Low</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  // width: "80%",
                  borderRadius: "2px",
                  paddingBottom: "16px",
                  // marginLeft: "10%",
                }}
              >
                <p
                  style={{
                    color: "rgba(97, 94, 131, 1)",
                    textAlign: "left",
                    paddingLeft: "20px",
                  }}
                >
                  Observations
                </p>
                <Divider variant="middle" />
                <div>
                  <ul>
                    <li>
                      The transaction size aligns favourably with our
                      expectations based on historical trends.
                    </li>
                    <li>
                      The trader involved has a commendable track record of
                      financial responsibility.
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TransactionReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 60% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
  }
  .main-content {
    margin-top: 30px;
    // width: 50%;
  }
`;
